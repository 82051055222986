// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$down-arrow-s-name: 'down-arrow-s';
$down-arrow-s-x: 0px;
$down-arrow-s-y: 0px;
$down-arrow-s-offset-x: 0px;
$down-arrow-s-offset-y: 0px;
$down-arrow-s-width: 5px;
$down-arrow-s-height: 3px;
$down-arrow-s-total-width: 24px;
$down-arrow-s-total-height: 468px;
$down-arrow-s-image: '../img/sprite.png';
$down-arrow-s: (0px, 0px, 0px, 0px, 5px, 3px, 24px, 468px, '../img/sprite.png', 'down-arrow-s', );
$down-arrow-name: 'down-arrow';
$down-arrow-x: 0px;
$down-arrow-y: 8px;
$down-arrow-offset-x: 0px;
$down-arrow-offset-y: -8px;
$down-arrow-width: 9px;
$down-arrow-height: 5px;
$down-arrow-total-width: 24px;
$down-arrow-total-height: 468px;
$down-arrow-image: '../img/sprite.png';
$down-arrow: (0px, 8px, 0px, -8px, 9px, 5px, 24px, 468px, '../img/sprite.png', 'down-arrow', );
$dreamteam-name: 'dreamteam';
$dreamteam-x: 0px;
$dreamteam-y: 328px;
$dreamteam-offset-x: 0px;
$dreamteam-offset-y: -328px;
$dreamteam-width: 24px;
$dreamteam-height: 24px;
$dreamteam-total-width: 24px;
$dreamteam-total-height: 468px;
$dreamteam-image: '../img/sprite.png';
$dreamteam: (0px, 328px, 0px, -328px, 24px, 24px, 24px, 468px, '../img/sprite.png', 'dreamteam', );
$envelope-name: 'envelope';
$envelope-x: 0px;
$envelope-y: 196px;
$envelope-offset-x: 0px;
$envelope-offset-y: -196px;
$envelope-width: 22px;
$envelope-height: 18px;
$envelope-total-width: 24px;
$envelope-total-height: 468px;
$envelope-image: '../img/sprite.png';
$envelope: (0px, 196px, 0px, -196px, 22px, 18px, 24px, 468px, '../img/sprite.png', 'envelope', );
$facebook-name: 'facebook';
$facebook-x: 0px;
$facebook-y: 299px;
$facebook-offset-x: 0px;
$facebook-offset-y: -299px;
$facebook-width: 24px;
$facebook-height: 24px;
$facebook-total-width: 24px;
$facebook-total-height: 468px;
$facebook-image: '../img/sprite.png';
$facebook: (0px, 299px, 0px, -299px, 24px, 24px, 24px, 468px, '../img/sprite.png', 'facebook', );
$instagram-name: 'instagram';
$instagram-x: 0px;
$instagram-y: 270px;
$instagram-offset-x: 0px;
$instagram-offset-y: -270px;
$instagram-width: 24px;
$instagram-height: 24px;
$instagram-total-width: 24px;
$instagram-total-height: 468px;
$instagram-image: '../img/sprite.png';
$instagram: (0px, 270px, 0px, -270px, 24px, 24px, 24px, 468px, '../img/sprite.png', 'instagram', );
$locked-g-name: 'locked-g';
$locked-g-x: 0px;
$locked-g-y: 84px;
$locked-g-offset-x: 0px;
$locked-g-offset-y: -84px;
$locked-g-width: 15px;
$locked-g-height: 17px;
$locked-g-total-width: 24px;
$locked-g-total-height: 468px;
$locked-g-image: '../img/sprite.png';
$locked-g: (0px, 84px, 0px, -84px, 15px, 17px, 24px, 468px, '../img/sprite.png', 'locked-g', );
$locked-w-name: 'locked-w';
$locked-w-x: 0px;
$locked-w-y: 128px;
$locked-w-offset-x: 0px;
$locked-w-offset-y: -128px;
$locked-w-width: 15px;
$locked-w-height: 17px;
$locked-w-total-width: 24px;
$locked-w-total-height: 468px;
$locked-w-image: '../img/sprite.png';
$locked-w: (0px, 128px, 0px, -128px, 15px, 17px, 24px, 468px, '../img/sprite.png', 'locked-w', );
$man-user-g-name: 'man-user-g';
$man-user-g-x: 0px;
$man-user-g-y: 106px;
$man-user-g-offset-x: 0px;
$man-user-g-offset-y: -106px;
$man-user-g-width: 13px;
$man-user-g-height: 17px;
$man-user-g-total-width: 24px;
$man-user-g-total-height: 468px;
$man-user-g-image: '../img/sprite.png';
$man-user-g: (0px, 106px, 0px, -106px, 13px, 17px, 24px, 468px, '../img/sprite.png', 'man-user-g', );
$man-user-w-name: 'man-user-w';
$man-user-w-x: 0px;
$man-user-w-y: 40px;
$man-user-w-offset-x: 0px;
$man-user-w-offset-y: -40px;
$man-user-w-width: 13px;
$man-user-w-height: 17px;
$man-user-w-total-width: 24px;
$man-user-w-total-height: 468px;
$man-user-w-image: '../img/sprite.png';
$man-user-w: (0px, 40px, 0px, -40px, 13px, 17px, 24px, 468px, '../img/sprite.png', 'man-user-w', );
$mmgp-name: 'mmgp';
$mmgp-x: 0px;
$mmgp-y: 242px;
$mmgp-offset-x: 0px;
$mmgp-offset-y: -242px;
$mmgp-width: 23px;
$mmgp-height: 23px;
$mmgp-total-width: 24px;
$mmgp-total-height: 468px;
$mmgp-image: '../img/sprite.png';
$mmgp: (0px, 242px, 0px, -242px, 23px, 23px, 24px, 468px, '../img/sprite.png', 'mmgp', );
$roboto-l-name: 'roboto-l';
$roboto-l-x: 0px;
$roboto-l-y: 219px;
$roboto-l-offset-x: 0px;
$roboto-l-offset-y: -219px;
$roboto-l-width: 14px;
$roboto-l-height: 18px;
$roboto-l-total-width: 24px;
$roboto-l-total-height: 468px;
$roboto-l-image: '../img/sprite.png';
$roboto-l: (0px, 219px, 0px, -219px, 14px, 18px, 24px, 468px, '../img/sprite.png', 'roboto-l', );
$search-w-name: 'search-w';
$search-w-x: 0px;
$search-w-y: 18px;
$search-w-offset-x: 0px;
$search-w-offset-y: -18px;
$search-w-width: 16px;
$search-w-height: 17px;
$search-w-total-width: 24px;
$search-w-total-height: 468px;
$search-w-image: '../img/sprite.png';
$search-w: (0px, 18px, 0px, -18px, 16px, 17px, 24px, 468px, '../img/sprite.png', 'search-w', );
$search-name: 'search';
$search-x: 0px;
$search-y: 62px;
$search-offset-x: 0px;
$search-offset-y: -62px;
$search-width: 16px;
$search-height: 17px;
$search-total-width: 24px;
$search-total-height: 468px;
$search-image: '../img/sprite.png';
$search: (0px, 62px, 0px, -62px, 16px, 17px, 24px, 468px, '../img/sprite.png', 'search', );
$skype-l-name: 'skype-l';
$skype-l-x: 0px;
$skype-l-y: 173px;
$skype-l-offset-x: 0px;
$skype-l-offset-y: -173px;
$skype-l-width: 18px;
$skype-l-height: 18px;
$skype-l-total-width: 24px;
$skype-l-total-height: 468px;
$skype-l-image: '../img/sprite.png';
$skype-l: (0px, 173px, 0px, -173px, 18px, 18px, 24px, 468px, '../img/sprite.png', 'skype-l', );
$telegram-l-name: 'telegram-l';
$telegram-l-x: 0px;
$telegram-l-y: 150px;
$telegram-l-offset-x: 0px;
$telegram-l-offset-y: -150px;
$telegram-l-width: 20px;
$telegram-l-height: 18px;
$telegram-l-total-width: 24px;
$telegram-l-total-height: 468px;
$telegram-l-image: '../img/sprite.png';
$telegram-l: (0px, 150px, 0px, -150px, 20px, 18px, 24px, 468px, '../img/sprite.png', 'telegram-l', );
$telegram-name: 'telegram';
$telegram-x: 0px;
$telegram-y: 357px;
$telegram-offset-x: 0px;
$telegram-offset-y: -357px;
$telegram-width: 24px;
$telegram-height: 24px;
$telegram-total-width: 24px;
$telegram-total-height: 468px;
$telegram-image: '../img/sprite.png';
$telegram: (0px, 357px, 0px, -357px, 24px, 24px, 24px, 468px, '../img/sprite.png', 'telegram', );
$twitter-name: 'twitter';
$twitter-x: 0px;
$twitter-y: 386px;
$twitter-offset-x: 0px;
$twitter-offset-y: -386px;
$twitter-width: 24px;
$twitter-height: 24px;
$twitter-total-width: 24px;
$twitter-total-height: 468px;
$twitter-image: '../img/sprite.png';
$twitter: (0px, 386px, 0px, -386px, 24px, 24px, 24px, 468px, '../img/sprite.png', 'twitter', );
$vk-name: 'vk';
$vk-x: 0px;
$vk-y: 415px;
$vk-offset-x: 0px;
$vk-offset-y: -415px;
$vk-width: 24px;
$vk-height: 24px;
$vk-total-width: 24px;
$vk-total-height: 468px;
$vk-image: '../img/sprite.png';
$vk: (0px, 415px, 0px, -415px, 24px, 24px, 24px, 468px, '../img/sprite.png', 'vk', );
$youtube-name: 'youtube';
$youtube-x: 0px;
$youtube-y: 444px;
$youtube-offset-x: 0px;
$youtube-offset-y: -444px;
$youtube-width: 24px;
$youtube-height: 24px;
$youtube-total-width: 24px;
$youtube-total-height: 468px;
$youtube-image: '../img/sprite.png';
$youtube: (0px, 444px, 0px, -444px, 24px, 24px, 24px, 468px, '../img/sprite.png', 'youtube', );
$spritesheet-width: 24px;
$spritesheet-height: 468px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($down-arrow-s, $down-arrow, $dreamteam, $envelope, $facebook, $instagram, $locked-g, $locked-w, $man-user-g, $man-user-w, $mmgp, $roboto-l, $search-w, $search, $skype-l, $telegram-l, $telegram, $twitter, $vk, $youtube, );
$spritesheet: (24px, 468px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
