$output-bourbon-deprecation-warnings: false;
@import "bootstrap-reboot.min";
@import "mixin";
@import "base";
@import "buttons";
@import "sprite";

.header-f {
	.header-container {
		max-width: 1330px;
		width: 100%;
		margin: 0 auto;
		padding: 0 15px;
	}

	.header-top-line {
		background-image: linear-gradient(180deg, rgb(44, 44, 44) 0%, rgb(74, 74, 74) 100%);
		width: 100%;
	}

	.header-content {
		width: 100%;
		background: url(../img/bg/bg_header.png) 50% 0 no-repeat;
		background-size: cover;
		padding: 12px 0 6px;
	}

	.main-menu-wrap {
		background: #ffffff;
		box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.14);
		width: 100%;
		border-top: 3px solid $accent;
	}

	.main-menu-top {
		text-align: center;
		.menu {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

		}
		li {
			display: inline-block;
			vertical-align: middle;
			flex-grow: 1;
			@include rb;
			position: relative;
		}
		> .menu li.active {
			a {
				background: #F5F5F5;
				color: $base;
			}
		}
		a {
			color: $base;
			display: block;
			transition: 0.3s ease-in-out;
			white-space: nowrap;
			padding: 14px 15px;
			font-size: 16px;
			@include rm;
			overflow: hidden;
			&:hover {
				background: #F5F5F5;
				color: $base;
			}
		}
		.menu-item-has-children {
			cursor: pointer;
			.menu-item-has-children {
				ul {
					margin-top: 0;
					top: 0;
					padding: 0;
					left: 60%;
					right: auto;
					transition: 0.4s;
					box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.14);
					overflow: hidden;
					border-radius: 4px;
					li {
						box-shadow: none;
					}
					&:before {
						display: none;
					}
				}
				&:hover {
					> ul {
						top: 0;
						left: 80%;
					}
				}
			}
			&:hover {
				> ul {
					visibility: visible;
					opacity: 1;
					top: 100%;
					transition: 0.4s;
				}
			}
			> a {
				padding-right: 34px;
				&:before {
					content: '';
					position: absolute;
					right: 20px;
					top: 50%;
					@include sprite($down-arrow);
					margin-top: -1px;
				}
				&:hover {
					color: $base;
				}
			}
			ul {
				position: absolute;
				display: inline-block;
				top: 160%;
				right: 0;
				padding: 20px 0 0;
				z-index: 5;
				visibility: hidden;
				opacity: 0;
				border-radius: 4px;
				&:before {
					content: '';
					position: absolute;
					top: 2px;
					right: 15px;
					width: 0;
					height: 0;
					border: 9px solid transparent;
					border-bottom-color: #ffffff;
					z-index: 2;
				}
				li {
					display: block;
					background-color: #ffffff;
					box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.14);
					position: relative;
					&:first-child {
						border-radius: 4px 4px 0 0;
					}
					&:last-child {
						border-radius: 0 0 4px 4px;
						a {
							border-bottom: none;
						}
					}
					a {
						color: rgba(90, 90, 90, 0.38);
						text-align: left;
						padding: 19px 30px;
						border-bottom: 1px solid rgba(0, 0, 0, 0.071);
						position: relative;
						background-color: transparent;
						&:before {
							content: '';
							position: absolute;
							width: 3px;
							height: calc(100% + 1px);
							background: $accent;
							top: 0;
							left: 0;
							@include transition;
							opacity: 0;
						}
						&:hover {
							color: $base;
							&:before {
								opacity: 1;
							}
						}
					}
				}
			}
			a {
				display: block;
			}
		}
	}

	.panel-navigation {
		overflow: hidden;
	}

	.panel-navigation-inner {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: $accent;
		min-height: 58px;
		padding: 12px 0;
		&:before, &:after {
			content: '';
			position: absolute;
			z-index: -1;
			transform-origin: 50% 50%;
			background: $accent;
			width: 100px;
			height: 100px;
			bottom: 0;
		}
		&:before {
			left: -30px;
			transform: rotate(-30deg);
		}
		&:after {
			right: -30px;
			transform: rotate(30deg);
		}
	}

	.navigation-list-wrap {
		width: 70%;
	}

	.button-list-wrap {
		width: 30%;
	}

	.navigation-list {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			right: 0;
			top: 50%;
			opacity: 0.2;
			height: 20px;
			margin-top: -10px;
			width: 1px;
			background: #ffffff;
		}
		li {
			flex-grow: 1;
			text-align: center;
			position: relative;
			padding: 0 10px;
			&:before {
				content: '';
				position: absolute;
				border-radius: 50%;
				background-color: #ffffff;
				opacity: 0.3;
				width: 6px;
				height: 6px;
				top: 50%;
				right: -4px;
				margin-top: -2px;
			}
			&:last-child {
				&:before {
					display: none;
				}
			}
			a {
				padding: 3px 0;
				display: inline-block;
				vertical-align: middle;
				color: #ffffff;
				@include rb;
				font-size: 13px;
				text-transform: uppercase;
				@include transition;
				max-width: 100%;
				@include text-overflow;
				position: relative;
				&:before, &:after {
					content: '';
					position: absolute;
					width: 0;
					@include transition;
					height: 1px;
					background-color: #ffffff;
					bottom: 0;
				}
				&:before {
					left: 50%;
				}
				&:after {
					right: 50%;
				}
				&:hover {
					opacity: 0.8;
					&:before, &:after {
						width: 50%;
					}
				}
			}
		}
	}

	.button-list {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 0 5px;
		li {
			flex-grow: 1;
			text-align: center;
			position: relative;
			padding: 0 10px;
			&:before {
				content: '';
				position: absolute;
				border-radius: 50%;
				background-color: #ffffff;
				opacity: 0.3;
				width: 6px;
				height: 6px;
				top: 50%;
				right: -4px;
				margin-top: -2px;
			}
			&:last-child {
				&:before {
					display: none;
				}
			}
		}
		a {
			display: block;
			border-radius: 4px;
			background-color: rgba(255, 255, 255, 0.09);
			height: 30px;
			padding: 0 10px;
			line-height: 28px;
			outline: none;
			color: #ffffff;
			text-transform: uppercase;
			@include rb;
			font-size: 13px;
			text-align: center;
			border: 1px solid rgba(255, 255, 255, 0.2);
			@include transition;
			max-width: 100%;
			@include text-overflow;
			&:hover {
				border-color: #ffffff;
				background-color: rgba(255, 255, 255, 0.2);
			}
		}
	}

	.lang {
		padding-top: 3px;
		padding-right: 15px;
		max-width: 70px;
	}

	.chooselang {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		height: 30px;
	}

	.selected-lang {
		position: relative;
		display: block;
		box-sizing: border-box;
		text-align: left;
		z-index: 2;
		cursor: pointer;
		span {
			@include rr;
			color: rgba(255, 255, 255, 0.302);
			font-size: 14px;
			text-decoration: none;
			display: inline-block;
			position: relative;
			box-sizing: border-box;
			padding-left: 28px;
			padding-right: 10px;
			line-height: 28px;
			@include text-overflow;
			.flag-icon {
				width: 22px;
				height: 13px;
				background-size: cover;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			&:before {
				content: '';
				position: absolute;
				@include sprite($down-arrow-s);
				top: 14px;
				right: 0;
			}
		}
		&.active {
			span {
				&:before {
					transform: rotate(180deg);
				}
			}
		}
	}

	.lang-list {
		position: absolute;
		z-index: 50;
		min-width: 60px;
		left: -10px;
		top: 104%;
		display: none;
		padding: 0;
		background: rgba(0, 0, 0, 0.6);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
		li {
			list-style-type: none;
			display: block;
			padding: 0 10px;
			&.active {
				background-color: $accent;
				span {
					color: #ffffff;
				}
			}
			&:hover {
				background-color: rgba(255, 255, 255, 0.3);
				span {
					color: #ffffff;
				}
			}
		}
		a {
			display: block;
		}
		span {
			@include transition;
			@include rr;
			color: rgba(255, 255, 255, 0.302);
			font-size: 14px;
			text-decoration: none;
			display: block;
			cursor: pointer;
			position: relative;
			text-align: left;
			@include text-overflow;
			padding-left: 28px;
			min-height: 22px;
			line-height: 28px;
			.flag-icon {
				width: 22px;
				height: 13px;
				background-size: cover;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.header-top-line-inner {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		min-height: 47px;
		.item {
			flex-grow: 1;
			position: relative;
			&:last-child {
				&:before {
					display: none;
				}
			}
			&:before {
				content: '';
				position: absolute;
				background: #ffffff;
				opacity: 0.09;
				width: 1px;
				height: 18px;
				right: 0;
				top: 50%;
				margin-top: -9px;
			}
		}
	}

	.support-link {
		ul {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			li {
				text-align: center;
				flex-grow: 1;
				padding: 0 10px;
			}
			a {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				padding-left: 26px;
				max-width: 100%;
				@include text-overflow;
				line-height: 20px;
				font-size: 14px;
				color: rgba(255, 255, 255, 0.302);
				text-align: left;
				@include transition;
				&:hover {
					color: #ffffff;
				}
			}
			.support-icon {
				position: absolute;
				left: 0;
				@include alignTransformVertical;
			}
		}
	}

	.chat-button {
		text-align: center;
		padding: 0 10px;
		a {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			padding-left: 26px;
			max-width: 100%;
			@include text-overflow;
			line-height: 20px;
			font-size: 14px;
			color: #00b3e7;
			text-align: left;
			@include transition;
			&:hover {
				color: #ffffff;
			}
		}
		.support-icon {
			position: absolute;
			left: 0;
			@include alignTransformVertical;
		}
	}

	.support-icon {
		display: block;
		width: 20px;
		height: 18px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			@include alignTransform;
			background-repeat: no-repeat;
		}
		&.telegram--icon {
			&:before {
				@include sprite($telegram-l);
			}
		}
		&.skype--icon {
			&:before {
				@include sprite($skype-l);
			}
		}
		&.robot--icon {
			&:before {
				@include sprite($roboto-l);
			}
		}
	}

	.user-link-list {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}

	.user-link-item__link {
		height: 47px;
		display: block;
		line-height: 47px;
		padding: 0 16px 0 40px;
		position: relative;
		font-size: 14px;
		text-transform: uppercase;
		@include transition;
		&:before {
			content: '';
			position: absolute;
			left: 16px;
			@include alignTransformVertical;
		}
		&.registration--link {
			background-color: $accent;
			color: #ffffff;
			padding: 0 20px 0 45px;
			&:before {
				@include sprite($man-user-w);
				left: 20px;
			}
		}
		&.login--link {
			color: rgba(255, 255, 255, 0.302);
			background-color: transparent;
			&:before {
				@include sprite($locked-g);
			}
		}
		&:hover {
			&.registration--link {
				background-color: #ba442b;
			}
			&.login--link {
				background-color: rgba(255, 255, 255, 0.3);
				color: #ffffff;
				&:before {
					@include sprite($locked-w);
				}
			}
		}
	}

	.email-link {
		text-align: center;
		a {
			color: #ffffff;
			text-decoration: underline;
			position: relative;
			display: inline-block;
			vertical-align: middle;
			padding-left: 35px;
			position: relative;
			max-width: 100%;
			@include text-overflow;
			line-height: 18px;
			font-size: 14px;
			text-align: left;
			@include transition;
			&:hover {
				text-decoration: none;
			}
		}
		.email-icon {
			display: block;
			position: absolute;
			@include sprite($envelope);
			left: 0;
			@include alignTransformVertical;
		}
	}

	.header-content-top {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.logo-wrap {
		text-align: left;
		width: 30%;
		.logo {
			display: inline-block;
		}
	}

	.promo-img {
		text-align: right;
		width: 70%;
		a {
			display: inline-block;
			vertical-align: middle;
			@include transition;
			&:hover {
				box-shadow: 0 4px 10px 5px rgba(255, 255, 255, 0.2);
			}
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.header-content-bottom {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-top: 8px;
	}

	.social-buttons {
		width: 60%;
		ul {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 -9px;
		}
		li {
			padding: 0 9px;
			margin-bottom: 9px;
		}
		a {
			display: inline-block;
			vertical-align: middle;
			transition: 0.3s;
			border-radius: 50%;
			&:hover {
				box-shadow: 0 0 6px 3px rgba(#00b3e7, 0.7);
			}
		}
	}

	.social-icon {
		display: block;
		border-radius: 50%;
		background-repeat: no-repeat;
		&.mmgp--icon {
			@include sprite($mmgp);
		}
		&.dreamteam--icon {
			@include sprite($dreamteam);
		}
		&.twitter--icon {
			@include sprite($twitter);
		}
		&.youtube--icon {
			@include sprite($youtube);
		}
		&.vk--icon {
			@include sprite($vk);
		}
		&.facebook--icon {
			@include sprite($facebook);
		}
		&.telegram--icon {
			@include sprite($telegram);
		}
		&.instagram--icon {
			@include sprite($instagram);
		}
	}

	.form-search {
		position: relative;
		label {
			width: 100%;
			margin-bottom: 0;
		}
		input {
			height: 30px;
			width: 160px;
			@include transition;
			padding: 0 36px 0 15px;
			background-color: transparent;
			border: 1px solid transparent;
			border-radius: 4px;
			font-size: 12px;
			color: #ffffff;
			text-transform: uppercase;
			&::placeholder {
				color: rgba(255, 255, 255, 0.302);
				opacity: 1;
			}
			&:focus {
				width: 250px;
				border-color: rgba(255, 255, 255, 0.8);
				box-shadow: 0 0 4px rgba(255, 255, 255, 0.3);
			}
		}
	}

	.form-search__button {
		position: absolute;
		height: 30px;
		width: 30px;
		top: 0;
		right: 0;
		border: none;
		outline: none;
		background-color: transparent;
		z-index: 2;
		cursor: pointer;
		&:before, &:after {
			content: '';
			position: absolute;
			@include alignTransform;
			@include transition;
		}
		&:before {
			@include sprite($search);
			opacity: 1;
		}
		&:after {
			@include sprite($search-w);
			opacity: 0;
		}
		&:hover {
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}
}