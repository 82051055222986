@font-face {
  font-family: "RobotoBold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoBold/RobotoBold.woff") format("woff"), url("../fonts/RobotoBold/RobotoBold.ttf") format("truetype"), url("../fonts/RobotoBold/RobotoBold.svg#RobotoBold") format("svg");
}

@font-face {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/RobotoMedium/RobotoMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoMedium/RobotoMedium.woff") format("woff"), url("../fonts/RobotoMedium/RobotoMedium.ttf") format("truetype"), url("../fonts/RobotoMedium/RobotoMedium.svg#RobotoMedium") format("svg");
}

@font-face {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RobotoRegular/RobotoRegular.woff") format("woff"), url("../fonts/RobotoRegular/RobotoRegular.ttf") format("truetype"), url("../fonts/RobotoRegular/RobotoRegular.svg#RobotoRegular") format("svg");
}

/*!
 * Bootstrap Reboot v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*, ::after, ::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*# sourceMappingURL=bootstrap-reboot.min.css.map */
body {
  font-size: 16px;
  min-width: 1300px;
  height: 100%;
  position: relative;
  color: #5a5a5a;
  font-family: "RobotoRegular", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  padding: 0;
  margin: 0 auto;
  background-color: #ffffff;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

p {
  padding: 0;
  margin: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

body a,
body button {
  outline: none;
}

body a:hover, body a:focus,
body button:hover,
body button:focus {
  outline: none;
  text-decoration: none;
}

input:focus,
textarea:focus {
  outline: none;
}

.slick-slide:focus {
  outline: none;
}

.header-f .header-container {
  max-width: 1330px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.header-f .header-top-line {
  background-image: linear-gradient(180deg, #2c2c2c 0%, #4a4a4a 100%);
  width: 100%;
}

.header-f .header-content {
  width: 100%;
  background: url(../img/bg/bg_header.png) 50% 0 no-repeat;
  background-size: cover;
  padding: 12px 0 6px;
}

.header-f .main-menu-wrap {
  background: #ffffff;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.14);
  width: 100%;
  border-top: 3px solid #D34B2D;
}

.header-f .main-menu-top {
  text-align: center;
}

.header-f .main-menu-top .menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-f .main-menu-top li {
  display: inline-block;
  vertical-align: middle;
  flex-grow: 1;
  font-family: "RobotoBold";
  position: relative;
}

.header-f .main-menu-top > .menu li.active a {
  background: #F5F5F5;
  color: #5a5a5a;
}

.header-f .main-menu-top a {
  color: #5a5a5a;
  display: block;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  padding: 14px 15px;
  font-size: 16px;
  font-family: "RobotoMedium";
  overflow: hidden;
}

.header-f .main-menu-top a:hover {
  background: #F5F5F5;
  color: #5a5a5a;
}

.header-f .main-menu-top .menu-item-has-children {
  cursor: pointer;
}

.header-f .main-menu-top .menu-item-has-children .menu-item-has-children ul {
  margin-top: 0;
  top: 0;
  padding: 0;
  left: 60%;
  right: auto;
  transition: 0.4s;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.14);
  overflow: hidden;
  border-radius: 4px;
}

.header-f .main-menu-top .menu-item-has-children .menu-item-has-children ul li {
  box-shadow: none;
}

.header-f .main-menu-top .menu-item-has-children .menu-item-has-children ul:before {
  display: none;
}

.header-f .main-menu-top .menu-item-has-children .menu-item-has-children:hover > ul {
  top: 0;
  left: 80%;
}

.header-f .main-menu-top .menu-item-has-children:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
  transition: 0.4s;
}

.header-f .main-menu-top .menu-item-has-children > a {
  padding-right: 34px;
}

.header-f .main-menu-top .menu-item-has-children > a:before {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  background-image: url(../img/sprite.png);
  background-position: 0px -8px;
  width: 9px;
  height: 5px;
  margin-top: -1px;
}

.header-f .main-menu-top .menu-item-has-children > a:hover {
  color: #5a5a5a;
}

.header-f .main-menu-top .menu-item-has-children ul {
  position: absolute;
  display: inline-block;
  top: 160%;
  right: 0;
  padding: 20px 0 0;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  border-radius: 4px;
}

.header-f .main-menu-top .menu-item-has-children ul:before {
  content: '';
  position: absolute;
  top: 2px;
  right: 15px;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #ffffff;
  z-index: 2;
}

.header-f .main-menu-top .menu-item-has-children ul li {
  display: block;
  background-color: #ffffff;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.14);
  position: relative;
}

.header-f .main-menu-top .menu-item-has-children ul li:first-child {
  border-radius: 4px 4px 0 0;
}

.header-f .main-menu-top .menu-item-has-children ul li:last-child {
  border-radius: 0 0 4px 4px;
}

.header-f .main-menu-top .menu-item-has-children ul li:last-child a {
  border-bottom: none;
}

.header-f .main-menu-top .menu-item-has-children ul li a {
  color: rgba(90, 90, 90, 0.38);
  text-align: left;
  padding: 19px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.071);
  position: relative;
  background-color: transparent;
}

.header-f .main-menu-top .menu-item-has-children ul li a:before {
  content: '';
  position: absolute;
  width: 3px;
  height: calc(100% + 1px);
  background: #D34B2D;
  top: 0;
  left: 0;
  transition: 0.4s ease-in-out;
  opacity: 0;
}

.header-f .main-menu-top .menu-item-has-children ul li a:hover {
  color: #5a5a5a;
}

.header-f .main-menu-top .menu-item-has-children ul li a:hover:before {
  opacity: 1;
}

.header-f .main-menu-top .menu-item-has-children a {
  display: block;
}

.header-f .panel-navigation {
  overflow: hidden;
}

.header-f .panel-navigation-inner {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #D34B2D;
  min-height: 58px;
  padding: 12px 0;
}

.header-f .panel-navigation-inner:before, .header-f .panel-navigation-inner:after {
  content: '';
  position: absolute;
  z-index: -1;
  transform-origin: 50% 50%;
  background: #D34B2D;
  width: 100px;
  height: 100px;
  bottom: 0;
}

.header-f .panel-navigation-inner:before {
  left: -30px;
  transform: rotate(-30deg);
}

.header-f .panel-navigation-inner:after {
  right: -30px;
  transform: rotate(30deg);
}

.header-f .navigation-list-wrap {
  width: 70%;
}

.header-f .button-list-wrap {
  width: 30%;
}

.header-f .navigation-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.header-f .navigation-list:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  opacity: 0.2;
  height: 20px;
  margin-top: -10px;
  width: 1px;
  background: #ffffff;
}

.header-f .navigation-list li {
  flex-grow: 1;
  text-align: center;
  position: relative;
  padding: 0 10px;
}

.header-f .navigation-list li:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.3;
  width: 6px;
  height: 6px;
  top: 50%;
  right: -4px;
  margin-top: -2px;
}

.header-f .navigation-list li:last-child:before {
  display: none;
}

.header-f .navigation-list li a {
  padding: 3px 0;
  display: inline-block;
  vertical-align: middle;
  color: #ffffff;
  font-family: "RobotoBold";
  font-size: 13px;
  text-transform: uppercase;
  transition: 0.4s ease-in-out;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.header-f .navigation-list li a:before, .header-f .navigation-list li a:after {
  content: '';
  position: absolute;
  width: 0;
  transition: 0.4s ease-in-out;
  height: 1px;
  background-color: #ffffff;
  bottom: 0;
}

.header-f .navigation-list li a:before {
  left: 50%;
}

.header-f .navigation-list li a:after {
  right: 50%;
}

.header-f .navigation-list li a:hover {
  opacity: 0.8;
}

.header-f .navigation-list li a:hover:before, .header-f .navigation-list li a:hover:after {
  width: 50%;
}

.header-f .button-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
}

.header-f .button-list li {
  flex-grow: 1;
  text-align: center;
  position: relative;
  padding: 0 10px;
}

.header-f .button-list li:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.3;
  width: 6px;
  height: 6px;
  top: 50%;
  right: -4px;
  margin-top: -2px;
}

.header-f .button-list li:last-child:before {
  display: none;
}

.header-f .button-list a {
  display: block;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.09);
  height: 30px;
  padding: 0 10px;
  line-height: 28px;
  outline: none;
  color: #ffffff;
  text-transform: uppercase;
  font-family: "RobotoBold";
  font-size: 13px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.4s ease-in-out;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-f .button-list a:hover {
  border-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
}

.header-f .lang {
  padding-top: 3px;
  padding-right: 15px;
  max-width: 70px;
}

.header-f .chooselang {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 30px;
}

.header-f .selected-lang {
  position: relative;
  display: block;
  box-sizing: border-box;
  text-align: left;
  z-index: 2;
  cursor: pointer;
}

.header-f .selected-lang span {
  font-family: "RobotoRegular";
  color: rgba(255, 255, 255, 0.302);
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding-left: 28px;
  padding-right: 10px;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-f .selected-lang span .flag-icon {
  width: 22px;
  height: 13px;
  background-size: cover;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-f .selected-lang span:before {
  content: '';
  position: absolute;
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 5px;
  height: 3px;
  top: 14px;
  right: 0;
}

.header-f .selected-lang.active span:before {
  transform: rotate(180deg);
}

.header-f .lang-list {
  position: absolute;
  z-index: 50;
  min-width: 60px;
  left: -10px;
  top: 104%;
  display: none;
  padding: 0;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.header-f .lang-list li {
  list-style-type: none;
  display: block;
  padding: 0 10px;
}

.header-f .lang-list li.active {
  background-color: #D34B2D;
}

.header-f .lang-list li.active span {
  color: #ffffff;
}

.header-f .lang-list li:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.header-f .lang-list li:hover span {
  color: #ffffff;
}

.header-f .lang-list a {
  display: block;
}

.header-f .lang-list span {
  transition: 0.4s ease-in-out;
  font-family: "RobotoRegular";
  color: rgba(255, 255, 255, 0.302);
  font-size: 14px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  position: relative;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 28px;
  min-height: 22px;
  line-height: 28px;
}

.header-f .lang-list span .flag-icon {
  width: 22px;
  height: 13px;
  background-size: cover;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-f .header-top-line-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 47px;
}

.header-f .header-top-line-inner .item {
  flex-grow: 1;
  position: relative;
}

.header-f .header-top-line-inner .item:last-child:before {
  display: none;
}

.header-f .header-top-line-inner .item:before {
  content: '';
  position: absolute;
  background: #ffffff;
  opacity: 0.09;
  width: 1px;
  height: 18px;
  right: 0;
  top: 50%;
  margin-top: -9px;
}

.header-f .support-link ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.header-f .support-link ul li {
  text-align: center;
  flex-grow: 1;
  padding: 0 10px;
}

.header-f .support-link ul a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 26px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.302);
  text-align: left;
  transition: 0.4s ease-in-out;
}

.header-f .support-link ul a:hover {
  color: #ffffff;
}

.header-f .support-link ul .support-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-f .chat-button {
  text-align: center;
  padding: 0 10px;
}

.header-f .chat-button a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 26px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  font-size: 14px;
  color: #00b3e7;
  text-align: left;
  transition: 0.4s ease-in-out;
}

.header-f .chat-button a:hover {
  color: #ffffff;
}

.header-f .chat-button .support-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-f .support-icon {
  display: block;
  width: 20px;
  height: 18px;
  position: relative;
}

.header-f .support-icon:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
}

.header-f .support-icon.telegram--icon:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -150px;
  width: 20px;
  height: 18px;
}

.header-f .support-icon.skype--icon:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -173px;
  width: 18px;
  height: 18px;
}

.header-f .support-icon.robot--icon:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -219px;
  width: 14px;
  height: 18px;
}

.header-f .user-link-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-f .user-link-item__link {
  height: 47px;
  display: block;
  line-height: 47px;
  padding: 0 16px 0 40px;
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.4s ease-in-out;
}

.header-f .user-link-item__link:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.header-f .user-link-item__link.registration--link {
  background-color: #D34B2D;
  color: #ffffff;
  padding: 0 20px 0 45px;
}

.header-f .user-link-item__link.registration--link:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -40px;
  width: 13px;
  height: 17px;
  left: 20px;
}

.header-f .user-link-item__link.login--link {
  color: rgba(255, 255, 255, 0.302);
  background-color: transparent;
}

.header-f .user-link-item__link.login--link:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -84px;
  width: 15px;
  height: 17px;
}

.header-f .user-link-item__link:hover.registration--link {
  background-color: #ba442b;
}

.header-f .user-link-item__link:hover.login--link {
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.header-f .user-link-item__link:hover.login--link:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -128px;
  width: 15px;
  height: 17px;
}

.header-f .email-link {
  text-align: center;
}

.header-f .email-link a {
  color: #ffffff;
  text-decoration: underline;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 35px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  font-size: 14px;
  text-align: left;
  transition: 0.4s ease-in-out;
}

.header-f .email-link a:hover {
  text-decoration: none;
}

.header-f .email-link .email-icon {
  display: block;
  position: absolute;
  background-image: url(../img/sprite.png);
  background-position: 0px -196px;
  width: 22px;
  height: 18px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-f .header-content-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.header-f .logo-wrap {
  text-align: left;
  width: 30%;
}

.header-f .logo-wrap .logo {
  display: inline-block;
}

.header-f .promo-img {
  text-align: right;
  width: 70%;
}

.header-f .promo-img a {
  display: inline-block;
  vertical-align: middle;
  transition: 0.4s ease-in-out;
}

.header-f .promo-img a:hover {
  box-shadow: 0 4px 10px 5px rgba(255, 255, 255, 0.2);
}

.header-f img {
  max-width: 100%;
  height: auto;
}

.header-f .header-content-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.header-f .social-buttons {
  width: 60%;
}

.header-f .social-buttons ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -9px;
}

.header-f .social-buttons li {
  padding: 0 9px;
  margin-bottom: 9px;
}

.header-f .social-buttons a {
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s;
  border-radius: 50%;
}

.header-f .social-buttons a:hover {
  box-shadow: 0 0 6px 3px rgba(0, 179, 231, 0.7);
}

.header-f .social-icon {
  display: block;
  border-radius: 50%;
  background-repeat: no-repeat;
}

.header-f .social-icon.mmgp--icon {
  background-image: url(../img/sprite.png);
  background-position: 0px -242px;
  width: 23px;
  height: 23px;
}

.header-f .social-icon.dreamteam--icon {
  background-image: url(../img/sprite.png);
  background-position: 0px -328px;
  width: 24px;
  height: 24px;
}

.header-f .social-icon.twitter--icon {
  background-image: url(../img/sprite.png);
  background-position: 0px -386px;
  width: 24px;
  height: 24px;
}

.header-f .social-icon.youtube--icon {
  background-image: url(../img/sprite.png);
  background-position: 0px -444px;
  width: 24px;
  height: 24px;
}

.header-f .social-icon.vk--icon {
  background-image: url(../img/sprite.png);
  background-position: 0px -415px;
  width: 24px;
  height: 24px;
}

.header-f .social-icon.facebook--icon {
  background-image: url(../img/sprite.png);
  background-position: 0px -299px;
  width: 24px;
  height: 24px;
}

.header-f .social-icon.telegram--icon {
  background-image: url(../img/sprite.png);
  background-position: 0px -357px;
  width: 24px;
  height: 24px;
}

.header-f .social-icon.instagram--icon {
  background-image: url(../img/sprite.png);
  background-position: 0px -270px;
  width: 24px;
  height: 24px;
}

.header-f .form-search {
  position: relative;
}

.header-f .form-search label {
  width: 100%;
  margin-bottom: 0;
}

.header-f .form-search input {
  height: 30px;
  width: 160px;
  transition: 0.4s ease-in-out;
  padding: 0 36px 0 15px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
}

.header-f .form-search input::placeholder {
  color: rgba(255, 255, 255, 0.302);
  opacity: 1;
}

.header-f .form-search input:focus {
  width: 250px;
  border-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.3);
}

.header-f .form-search__button {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 2;
  cursor: pointer;
}

.header-f .form-search__button:before, .header-f .form-search__button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.4s ease-in-out;
}

.header-f .form-search__button:before {
  background-image: url(../img/sprite.png);
  background-position: 0px -62px;
  width: 16px;
  height: 17px;
  opacity: 1;
}

.header-f .form-search__button:after {
  background-image: url(../img/sprite.png);
  background-position: 0px -18px;
  width: 16px;
  height: 17px;
  opacity: 0;
}

.header-f .form-search__button:hover:before {
  opacity: 0;
}

.header-f .form-search__button:hover:after {
  opacity: 1;
}
