// Variables Bootstrap 4

$grid-breakpoints: (
	// Extra small screen / phone
	xs: 0,
	// Small screen / phone
	sm: 576px,
	// Medium screen / tablet
	md: 767px,
	// Large screen / desktop
	lg: 991px,
	// Extra large screen / wide desktop
	xl: 1200px
);

$container-max-widths: (
	//sm: 576px,
	md: 750px,
	lg: 970px,
	xl: 1170px
);

$n: 20;

$base: #5a5a5a;
$accent: #D34B2D;
$gray: #5a5a5a;

$gradient: linear-gradient( 61deg, rgb(9,32,63) 0%, rgb(83,120,149) 99%);
$gradient-gray: linear-gradient( 61deg, rgb(200,200,200) 1%, rgb(245,245,245) 100%);