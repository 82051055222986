@import "bourbon";
@import "mixin";

body {
	font-size: 16px;
	min-width: 1300px;
	height: 100%;
	position: relative;
	color: #5a5a5a;
	font-family: "RobotoRegular", Helvetica, Arial, sans-serif;
	overflow-x: hidden;
	padding: 0;
	margin: 0 auto;
	background-color: #ffffff;
	line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
	font-weight: normal;
}

p {
	padding: 0;
	margin: 0;
}

ul,
ol {
	padding: 0;
	margin: 0;
	list-style: none;
}

body a,
body button {
	outline: none;
	&:hover,
	&:focus {
		outline: none;
		text-decoration: none;
	}
}

input,
textarea {
	&:focus {
		outline: none;
	}
}

.slick-slide:focus {
	outline: none;
}